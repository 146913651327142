<nz-input-group nzCompact>
  <nz-select [nzCustomTemplate]="countrySelectedTemplate"
             [(ngModel)]="selectedCountry"
             nzBorderless
             [nzDropdownMatchSelectWidth]="false"
             nzPlaceHolder="Select"
             [nzSuffixIcon]="'caret-down'"
             (ngModelChange)="countryChange()">
    <nz-option *ngFor="let country of countries"
               nzCustomContent
               [nzValue]="country">
      <img src="{{ country.image }}" [alt]="country.name" width="24"/> ({{ country.prefix }}) {{ country.name }}
    </nz-option>
  </nz-select>
  <ng-template #countrySelectedTemplate let-selected>
    <img src="{{ selected.nzValue.image }}" [alt]="selected.nzValue.name"/> ({{ selectedCountry.prefix }})
  </ng-template>

  <div class="input-container">
    <input #phoneNumber
           type="text"
           nz-input
           pattern="[0-9]*"
           [(ngModel)]="nationalNumber"
           style="width:150px;"
           class="national-number-input"
           (ngModelChange)="nationalNumberChange()" />
  </div>
</nz-input-group>
<div class="error-message">{{ phoneNumberValidationMessage }}</div>