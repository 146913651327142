<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Dashboard</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <h1>{{ userAccountTitle }}</h1>
    </div>
    <div nz-row>
      <div nz-col [nzSm]="24" [nzMd]="12" nzOffset="0">
        Welcome to <strong>EMPIST 360</strong>. This is your company Dashboard – <br/>
        Consider this your hub for all EMPIST services, support, latest news, and more, <br/>
        all tailored to your specific needs.
      </div>
    </div>
    <nz-divider></nz-divider>
    <div nz-row>
      <div nz-col nzSpan="24" nzOffset="0">
        <button nz-button
                nzType="default"
                nzShape="round"
                nzSize="large"
                [routerLink]="['/app', 'support']"
                class="dashboard-link">
          <span nz-icon nzType="comment" [nzTheme]="'outline'"></span>
          Support
        </button>
        <button *ngIf="loggedInUser.attributes.type == 'Digcore::Contact' && installedServicePermission"
                nz-button
                nzType="default"
                nzShape="round"
                nzSize="large"
                [routerLink]="['/app', 'services']"
                class="dashboard-link">
          <dw-icon type="dw:services" class="card-icon"></dw-icon>
          Services
        </button>
        <button *ngIf="loggedInUser.attributes.type == 'Digcore::Contact' && invoicesPermission"
                nz-button
                nzType="default"
                nzShape="round"
                nzSize="large"
                [routerLink]="['/app', 'invoices']"
                class="dashboard-link">
          <dw-icon type="dw:invoices" class="card-icon"></dw-icon>
          Invoices
        </button>
      </div>
    </div>

    <div nz-row class="articles-list" *ngIf="userAccountPosts?.length || tenantAccountPosts?.length">
      <div nz-col nzSpan="24">
        <div nz-row>
          <div nz-col nzSpan="12"><h3><strong>Featured News</strong></h3></div>
          <div nz-col nzSpan="12" class="tickets-link" *ngIf="postsIndexPermission">
            <a [routerLink]="['/app','news']">
              See All News
              <span nz-icon nzType="right" [nzTheme]="'outline'"></span>
            </a>
          </div>
        </div>
        <nz-divider></nz-divider>
      </div>
    </div>

    <nz-list nzGrid *ngIf="userAccountPosts?.length" [nzLoading]="userAccountPostsLoading">
      <div *ngIf="userAccountPosts?.length"
           nz-row
           [nzGutter]="16"
           class="account-posts">
        <a *ngFor="let post of userAccountPosts.slice(0,3)"
             nz-col
             [nzXs]="{ span: 24 }"
             [nzMd]="{ span: 8 }"
             class="post-entry"
             [routerLink]="['/app','news', post.id]">
          <div *ngIf="post.attributes.featured_image_url; else noImagePost"
               [style.background-image]="'url('+post.attributes.featured_image_url+')'"
               class="post-image">
            <span nz-icon nzType="arrow-up" [nzTheme]="'outline'" class="link-arrow"></span>
          </div>
          <ng-template #noImagePost>
            <div class="post-image no-image"></div>
          </ng-template>
          <div class="post-title">
            {{ userAccountTitle }} · {{ post.attributes.title }}
          </div>
          <div class="post-text">
            {{ post.attributes.description }}
          </div>
        </a>
      </div>
    </nz-list>
    <nz-list nzGrid *ngIf="tenantAccountPosts?.length" [nzLoading]="tenantAccountPostsLoading">
      <div nz-row
           [nzGutter]="16"
           class="account-posts">
        <a *ngFor="let post of tenantAccountPosts.slice(0,3)"
             nz-col
             [nzXs]="{ span: 24 }"
             [nzMd]="{ span: 8 }"
             class="post-entry"
             [routerLink]="['/app','news', post.id]">
          <nz-list-item>
            <div *ngIf="post.attributes.featured_image_url; else noImagePost"
                 [style.background-image]="'url('+post.attributes.featured_image_url+')'"
                 class="post-image">
              <span nz-icon nzType="arrow-up" [nzTheme]="'outline'" class="link-arrow"></span>
            </div>
            <ng-template #noImagePost>
              <div class="post-image no-image"></div>
            </ng-template>
            <div class="post-title">
              {{ tenantAccountTitle }} · {{ post.attributes.title }}
            </div>
            <div class="post-text">
              {{ post.attributes.description }}
            </div>
          </nz-list-item>
        </a>
      </div>
    </nz-list>
    <div nz-row class="tickets-list">
      <div nz-col nzSpan="24">
        <div nz-row *ngIf="latestTickets">
          <div nz-col nzSpan="12"><h3><strong>Latest Tickets</strong></h3></div>
          <div nz-col nzSpan="12" class="tickets-link">
            <a [routerLink]="['/app','support','tickets']">
              See All Tickets
              <span nz-icon nzType="right" [nzTheme]="'outline'"></span>
            </a>
          </div>
        </div>

        <nz-divider></nz-divider>
        <nz-list nzItemLayout="vertical" [nzLoading]="loading">
          <div nz-row
               *ngFor="let ticket of latestTickets"
               class="dashboard-ticket-item">
            <div nz-col
                 [nzXs]="24"
                 [nzSm]="24"
                 class="ticket-information">
              <div nz-row [routerLink]="['/app','support', 'tickets', ticket.attributes.code]">
                <div nz-col nzSpan="24" class="ticket-row-top">
                  <span class="ticket-code"><span>Ticket: {{ ticket.attributes.code }}</span></span>
                  <span class="ticket-status"
                        [ngClass]="ticket.relationships?.status?.attributes?.state == 'in_progress' ? 'status-open' : 'status-completed'">
                    {{ ticket.relationships?.status?.attributes?.state == 'in_progress' ? 'Open' : 'Completed'  }}
                    <span class="ticket-created-at">
                      {{ ticket.attributes.created_at | dateAgo }}
                    </span>
                  </span>
                  <span class="ticket-requester">
                    <span *ngIf="ticket.relationships?.requester?.attributes?.fullname">
                      by
                      <nz-avatar [nzSize]="16"
                                 nzIcon="user"
                                 [nzSrc]="ticket.relationships?.requester?.attributes?.avatar"
                                 *ngIf="ticket.relationships?.requester?.attributes?.avatar">
                      </nz-avatar>
                      <nz-avatar [nzSize]="16"
                                 nzIcon="user"
                                 *ngIf="!ticket.relationships?.requester?.attributes?.avatar">
                      </nz-avatar>
                      {{ ticket.relationships?.requester?.attributes?.fullname }}
                    </span>
                  </span>
                </div>
              </div>
              <div nz-row [routerLink]="['/app','support','tickets', ticket.attributes.code]">
                <div nz-col nzSpan="24" class="ticket-row-subject">
                  <span *ngIf="!ticket.attributes.striped_description">
                    <strong>{{ticket.attributes.subject}}</strong>
                  </span>
                  <span *ngIf="ticket.attributes.striped_description">
                    <strong>{{ticket.attributes.subject}}:</strong>{{ticket.attributes.striped_description | slice: 0:100}}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div nz-row nzJustify="center">
            <div nz-col>
              <nz-list-empty *ngIf="latestTickets?.length === 0"></nz-list-empty>
            </div>
          </div>
        </nz-list>
      </div>
    </div>
  </nz-content>
</nz-layout>
