import { Component, DestroyRef, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { BehaviorSubject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { FlowsService } from 'src/app/services/flows.service';
import { QueryParam } from 'src/app/utilities/models/parameters/queryParam/queryParam';
import { Flow } from 'src/app/utilities/models/ticket/flow/flow';

@Component({
  selector: 'app-flow-list',
  templateUrl: './flow-list.component.html',
  styleUrls: ['./flow-list.component.scss'],
})
export class FlowListComponent implements OnInit {
  flows: Flow[];
  loading:boolean = false;
  pageIndex: number = 1;
  pageSize: number = 100;
  total: number = 0;
  totalPages: number = 0;
  searchString: string = '';
  currentQueryParams: QueryParam[] = [];
  sortProp: string = 'title';
  sortOrder: string = 'ascend';
  searchSubject$ = new BehaviorSubject<string>('');

  constructor(private flowService: FlowsService,
    private destroyRef: DestroyRef) {
  }

  ngOnInit(): void {
    this.setSearchSubject();
    //this.getFlows();
  }

  getFlows(queryParams: QueryParam[] = [], pageSize: number = this.pageSize, pageIndex: number = 1, sortField: string = 'title', sortOrder: string = 'ascend') {
    this.loading = true;
    this.flowService.getFlows(queryParams, pageSize, pageIndex, sortField, sortOrder, this.searchString)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(response: any) => {
          this.flows = response.data.map((row: any) => new Flow(row, response.included));
          this.total = response.meta.total_count;

          this.prepareFlowsJson();
        },
        error:(error) => {
          console.log(error);
          this.loading = false;
        },
        complete:() => {
          this.loading = false;
        },
      });
  }

  prepareFlowsJson() {
    const mapped: any[] = [];
    let mappedControls: any[] = [];
    this.flows.filter(flow => flow.attributes.platform_name == 'Onspring').forEach(flow => {
      const onspringGuids: any = {
        "Cleaning": "c3f2d255-ea9b-489d-8b92-1a050b920bfc",
        "Dental Equipment": "f59617b4-96ae-4a1e-803a-09a4168c734e",
        "Electric": "dcbdbe06-7ff8-4f8a-9890-9e663007000b",
        "HVAC": "3b8c5010-faaf-44fc-82f0-b1fb808c9221",
        "Plumbing": "c9b71e31-b650-4284-8e7b-b8420af05f77",
        "Damage": "e27b2ec6-4f4f-4dbf-9f1d-a7f1f3bb104d",
        "Loaner": "583ab916-d88c-4d9c-8880-5a6e8fcd5382",
        "Maintenance": "d9fae533-ae79-467c-b18f-4b36d2863317",
        "Mechanical": "6a9c96e3-2011-4d0d-9ff2-5750c1dfa20b",
        "Badge": "11f77779-250f-4078-9e4d-ad0befbe0f33",
        "Callback Request": "b0b7f135-e884-4047-98cb-410fa2948175",
        "Application Support": "32ad2e47-9407-4ba2-bd5c-547c2d86cfd6",
        "HQ Onsite Support": "4ed5d4dd-ab27-49ea-ae97-12c834f8b9ac",
        "Token": "5cda6cf2-76d6-4af2-b1a3-9dceb48b94e7",
        "Add": "ba5db8da-9f33-4524-b2d2-3cfc27f1559a",
        "Change": "26f7e5ef-c614-4fa6-a725-522b831ed0ad",
        "Move": "c60557d2-5b54-47de-9363-ad5bc4cee61a",
        "Delete": "a72caa3a-9f71-4fd6-879b-12c0485e3920",
        "Other": "2a2e1133-0898-45e9-97aa-0ef7ac2c3798"
      }
      const onspring_controls: any = {
        "Additional Comments":	6366,
        "Assigned To":	3374,
        "Badge Replacement Reason":	6369,
        "Replacement Reason":	6370,
        "Best Callback Number":	6361,
        "Best Time to Reach You":	6362,
        "Child Support Tickets":	6368,
        "Created By":	3413,
        "Created Date":	3668,
        "Date/Time Opened":	3388,
        "Department":	6376,
        "Description":	3487,
        "Disable Account Date":	6389,
        "Disable Account Date (user on leave)":	6389,
        "Empist Ticket Number":	6357,
        "Full Name":	6373,
        "Last Saved By":	3472,
        "Last User Save Date":	3432,
        "New Department":	6382,
        "New Full Name":	6380,
        "New Location":	6388,
        "New Position":	6384,
        "New Supervisor":	6386,
        "OE Location":	6364,
        "Office Location for This Issue":	6363,
        "Office Location":	6363,
        "Old Department":	6381,
        "Old Full Name":	6379,
        "Old Location":	6387,
        "Old Position":	6383,
        "Old Supervisor":	6385,
        "Position":	6377,
        "Preferred First Name":	6374,
        "Primary Support Ticket":	6367,
        "Re-enable Account Date":	6390,
        "Re-enable Account Date (user on leave)":	6390,
        "Record Id":	3390,
        "Resolution Description":	3383,
        "Status":	3409,
        "Submitter":	6358,
        "Submitter Email Address":	6392,
        "Submitter Email Address (Manual)":	6360,
        "Submitter Full Name":	6391,
        "Submitter Full Name (Manual)":	6359,
        "Supervisor":	6378,
        "Ticket Completion Status":	3558,
        "Ticket Sub-Type":	6372,
        "Ticket Type":	6371,
        "Title":	3535,
        "Token Replacement Reason":	6370,
        "Updated By": 3590,
        "Updated Date": 356,
      }
      const onspring_controlsLC: any = {}
      Object.keys(onspring_controls).forEach(key => {
        onspring_controlsLC[key.toLowerCase()] = onspring_controls[key];
      })
      mapped.push({deskwareId: flow.id, title: flow.attributes.title, onspringGuid: onspringGuids[flow.attributes.title] ?? ""});
      mappedControls = mappedControls.concat(flow.relationships?.flow_controls?.map(control => {
        const d: any = {
          "deskwareId": control.id,
          "onspringId": onspring_controlsLC[control.attributes.label.toLowerCase()] ?? "",
          "label": control.attributes.label,
          "name": control.attributes.name,
        }

        if(control.attributes.input_type == 'radio'){
          d['values'] = control.attributes.options?.map(option => {
            let optionId = "";
            switch(option.value) {
              case "My badge has an error and needs to be replaced": optionId = "f25e3420-8565-4a3a-8b1c-a6c87db6b0fc"; break;
              case "I lost my badge and need a replacement. I understand that by requesting a replacement badge I will be responsible for a fee of $5.00 which will be deducted out of my next check.": optionId = "dc6621dc-744f-4a40-ae7d-177fec8cf2d9"; break;
              case "My token is not working properly and needs to be replaced": optionId = "e92c965c-3b2d-48b0-bed8-625d16afdb51"; break;
              case "I lost or damaged my token and need a replacement. I understand that by requesting a replacement token I will be responsible for a fee of $30.00 which will be deducted out of my next check.": optionId = "d9a4e169-0c60-4065-897a-1f4ac4259170"; break;
            }
            return {
              "title": option.value,
              "onspringId": optionId,
              "deskwareFlowControlOptionId": null
            }
          })
        }

        return d;
      }))
    })
    console.log(mapped)
    console.log(mappedControls)
  }

  onPageIndexChange(indexNumber: number) {
    //console.log('onPageIndexChange');
    this.pageIndex = indexNumber;
    this.getFlows(this.currentQueryParams, this.pageSize, this.pageIndex, this.sortProp, this.sortOrder);
  }

  onPageSizeChange(sizeNumber: number) {
    //console.log('onPageSizeChange');
    this.pageIndex = 1
    this.pageSize = sizeNumber;
    this.getFlows(this.currentQueryParams, this.pageSize, this.pageIndex, this.sortProp, this.sortOrder);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    //console.log('onQueryParamsChange', params);
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'title';
    const sortOrder = (currentSort && currentSort.value) || 'ascend';
    if(this.pageSize != pageSize || this.pageIndex != pageIndex || this.sortProp != sortField || this.sortOrder != sortOrder) {
      this.sortProp = sortField;
      this.sortOrder = sortOrder;
      this.getFlows(this.currentQueryParams, params.pageSize, params.pageIndex, sortField, sortOrder);
    }
  }

  searchFlows() {
    if(this.searchString.length == 0 || this.searchString.length > 2) {
      this.searchSubject$.next(this.searchString);
    }
  }

  private setSearchSubject() {
    this.searchSubject$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
        switchMap(async() => this.getFlows(this.currentQueryParams, this.pageSize, 1, this.sortProp, this.sortOrder))
      )
      .subscribe({
        next: (_data) => { }
      })
  }
}
