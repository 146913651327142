<div class="modal-content">
  <div class="modal-heading">360 Meeting</div>
  <div class="modal-subheading">
    Select an agent to schedule a call
  </div>
  <label>Book a meeting with:</label>
  <nz-select nzSuffixIcon="caret-down"
             [nzCustomTemplate]="avatarUserTemplate"
             [(ngModel)]="selectedBookingUrl"
             class="agent-picker">
    <nz-option *ngFor="let bookingUrl of bookingUrls"
               [nzValue]="bookingUrl"
               [nzLabel]="bookingUrl.attributes.fullname"
               [nzCustomContent]="true">
      <nz-avatar [nzSize]="16" nzIcon="user" [nzSrc]="bookingUrl.attributes.avatar" class="selector-avatar"></nz-avatar>
      <span>{{ bookingUrl.attributes.fullname }}</span>
    </nz-option>
  </nz-select>
  <ng-template #avatarUserTemplate let-selected>
    <nz-avatar nzIcon="user" [nzSrc]="selected.nzValue.attributes.avatar" class="selected-avatar"></nz-avatar>
    {{ selected.nzLabel }}
  </ng-template>
  <button nz-button class="blue-btn" nzSize="large" nzShape="round" (click)="goToBookMeeting(selectedBookingUrl)" *ngIf="selectedBookingUrl">Next Step</button>
  <div class="powered-by">
    <span class="">Powered by</span>
    <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.71753 6.6V3.6773C4.71753 3.49828 4.88764 3.36738 5.06358 3.4089C6.58179 3.76667 7.75285 5.00528 7.99649 6.55133C8.02314 6.72045 7.89465 6.875 7.72177 6.875H4.99503C4.84185 6.875 4.71753 6.7518 4.71753 6.6ZM3.88503 6.6V0.275C3.88503 0.1232 3.76071 0 3.60752 0H0.277502C0.124321 0 0 0.1232 0 0.275V3.3C0 5.2745 1.61506 6.875 3.60752 6.875C3.76071 6.875 3.88503 6.7518 3.88503 6.6ZM0 7.975V10.725C0 10.8768 0.124321 11 0.277502 11H3.88503C3.88503 11 4.06873 11 4.16253 11C5.99876 11 7.53334 9.73638 7.9385 8.03935C7.97984 7.86638 7.84609 7.7 7.66682 7.7H0.277502C0.124321 7.7 0 7.8232 0 7.975Z" fill="#8C8E91"/>
    </svg>
    <span class="">Microsoft Bookings</span>
  </div>
</div>