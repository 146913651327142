<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Book a meeting</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <iframe [src]="bookingUrl | safe: 'resourceUrl'" width='100%' height='100%' scrolling='yes' style='border:0' *ngIf="bookingUrl"></iframe>
    <div *ngIf="!bookingUrl" nz-row class="no-booking-url" nzJustify="center">
      <div nz-col>
        You can only book a meeting when coming through a ticket.<br/>
        Go to <a [routerLink]="['/app','dashboard']">dashboard</a>
      </div>
    </div>
  </nz-content>
</nz-layout>