import { ObjectData } from "../objectData";

interface BookingUrlAttributes {
  booking_url: string;
  fullname: string;
  avatar: string;
}
interface BookingUrlRelationships {

}

export class BookingUrl  extends ObjectData<BookingUrlAttributes, BookingUrlRelationships> {
  constructor(data: ObjectData<BookingUrlAttributes, BookingUrlRelationships>, included: Object[]) {
    super(data, included);
  }
}