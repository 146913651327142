import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AuthService } from "./auth/auth.service";
import { TokenService } from "./auth/token.service";
import { UsersService } from "./services/users.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: AuthService,
              private tokenService: TokenService,
              private userService: UsersService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    if(this.checkLogin(url) && this.userService.permissionsArray.length == 0) {
      return this.userService.fetchPermissions().pipe(
        tap(_response => {{
          return this.checkRoutePermissionByUserType(route) && this.hasPermissionForRoute(route);
        }})
      )
    }else{
      return this.checkLogin(url) && this.checkRoutePermissionByUserType(route) && this.hasPermissionForRoute(route);
    }
  }

  private checkLogin(url: string): boolean {
    if (this.tokenService.getToken()) {
      return true;
    }

    localStorage.setItem('initial_url', url);
    this.authService.login();
    return false;
  }

  private checkRoutePermissionByUserType(route: ActivatedRouteSnapshot): boolean {
    const agentOnlyRoutes = [
      'services-audit'
    ];
    const contactOnlyRoutes = [
      'services','support'
    ];

    let result = true;
    const baseSegment = route.url[0].path;
    switch(this.userService.loggedInUser?.attributes.type){
      case 'Ticketing::Agent':
        result = !contactOnlyRoutes.includes(baseSegment);
        break;
      case 'Digcore::Contact':
        result = !agentOnlyRoutes.includes(baseSegment);
        break;
    }

    return true;
    //return result;
  }

  private hasPermissionForRoute(route: ActivatedRouteSnapshot) {
    let permission = null;
    switch(route.routeConfig?.path){
      case 'support/tickets':
        permission = !!this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'index');
        break;
      case 'support/tickets/:id':
        permission = !!this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'update');
        break;
      case 'support/tickets/new':
        permission = !!this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'create');
        break;
      case 'profile':
        permission = !!this.userService.findPermission('Digcore::User', 'ticketing/operator/v1/users', 'profile');
        break;
      case 'news':
        permission = !!this.userService.findPermission('Digcore::Post', 'ticketing/operator/v1/posts', 'index');
        break;
      case 'news/:id':
        permission = !!this.userService.findPermission('Digcore::Post', 'ticketing/operator/v1/posts', 'show');
        break;
      case 'news/:id/edit':
        permission = !!this.userService.findPermission('Digcore::Post', 'ticketing/operator/v1/posts', 'update');
        break;
      case 'invoices':
        permission = !!this.userService.findPermission('Digcore::Invoice', 'ticketing/operator/v1/invoices', 'index');
        break;
      case 'invoices/:id':
        permission = !!this.userService.findPermission('Digcore::Invoice', 'ticketing/operator/v1/invoices', 'show');
        break;
      case 'assets':
        permission = !!this.userService.findPermission('Ticketing::AssetResource', 'ticketing/operator/v1/asset_resources', 'index');
        break;
      case 'assets/:id':
        permission = !!this.userService.findPermission('Ticketing::AssetResource', 'ticketing/operator/v1/asset_resources', 'update');
        break;
      case 'uid/:base64': return true;
    }

    switch (route.url[0].path) {
      case 'services':
      case 'services-audit':
        permission = !!this.userService.findPermission('Digcore::InstalledService', 'ticketing/operator/v1/installed_services', 'index');
        break;
      default:
        return true;
    }

    if(!permission){
      this.router.navigateByUrl('/app/dashboard');
    }

    return !!permission;
  }
}
