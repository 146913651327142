import { Component, OnInit } from "@angular/core";
import { TicketsService } from "src/app/services/tickets.service";

@Component({
  selector: 'app-booking',
  templateUrl: './bookMeeting.component.html',
  styleUrls: ['./bookMeeting.component.scss']
})
export class BookMeetingComponent implements OnInit {
  bookingUrl: string;

  constructor(private ticketsService: TicketsService) {
  }

  ngOnInit() {
    this.bookingUrl = this.ticketsService.bookingUrl;
  }
}