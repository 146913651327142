import { Component, DestroyRef, OnInit } from "@angular/core";
import { UsersService } from "src/app/services/users.service";
import { Router } from "@angular/router";
import { User } from "src/app/utilities/models/user/user";
import { TicketsService } from "src/app/services/tickets.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'accountManager',
  templateUrl: './accountManager.component.html',
  styleUrls: ['./accountManager.component.scss']
})
export class AccountManagerComponent implements OnInit {
  accountManager: User;
  showInfoTooltip: boolean = true;
  showFullInfo: boolean = false;
  permission: Permission | undefined;

  constructor(private userService: UsersService,
              private ticketService: TicketsService,
              private msg: NzMessageService,
              private router: Router,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.permission = this.userService.findPermission('Digcore::User', 'ticketing/operator/v1/users', 'my_account_manager');
    this.getAccountManager();
  }

  private getAccountManager() {
    if(this.permission) {
      this.userService.getAccountManager()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            if(response?.data)
              this.accountManager = new User(response.data, response.included);
          }
        });
    }
  }

  closeInfoTooltip() {
    this.showInfoTooltip = false;
  }

  closeFullInfoTooltip() {
    this.showFullInfo = false;
  }

  showFullInfoTooltip() {
    this.showInfoTooltip = false;
    this.showFullInfo = true;
  }

  bookMeetingWithManager() {
    if(!this.accountManager?.attributes?.empist_microsoft_booking_url) return;

    this.ticketService.setBookingUrl(this.accountManager.attributes.empist_microsoft_booking_url);
    this.router.navigate(['/app','book-meeting']).then( _ => false);
    this.showFullInfo = false;
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.msg.success('Text copied')
  }
}