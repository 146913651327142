import { ObjectData } from "../objectData";
import { IncludedData } from "../includedData";
import { TicketRelationships } from "./ticketRelationships";
import { AccountRelationAttribute } from "./accountRelationAttribute";
import { RelationshipData } from "../relationshipData";
import { UserRelationAttribute } from "./userRelationAttribute";
import { Issue_typeRelationAttribute } from "./issue_typeRelationAttribute";
import { PriorityRelationAttribute } from "./priorityRelationAttribute";
import { Request_typeRelationAttribute } from "./request_typeRelationAttribute";
import { StatusRelationAttribute } from "./statusRelationAttribute";
import { UploadRelationAttribute } from "./uploadRelationAttribute";
import { TicketAttributes } from "./ticketAttributes";

export class Ticket extends ObjectData<TicketAttributes, TicketRelationships> {

  constructor(data: ObjectData<TicketAttributes, TicketRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included) {
      this.relationships = Ticket.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): TicketRelationships {
    let ticketRelationships = new TicketRelationships();

    ticketRelationships.account = Ticket.normalizeAccountRelation(relationObject, included);
    ticketRelationships.creator = Ticket.normalizeUserRelation(relationObject, included, 'creator');
    ticketRelationships.issue_type = Ticket.normalizeIssue_typeRelation(relationObject, included);
    ticketRelationships.priority = Ticket.normalizePriorityRelation(relationObject, included);
    ticketRelationships.request_type = Ticket.normalizeRequest_typeRelation(relationObject, included);
    ticketRelationships.requester = Ticket.normalizeUserRelation(relationObject, included, 'requester');
    ticketRelationships.status = Ticket.normalizeStatusRelation(relationObject, included);
    ticketRelationships.uploads = Ticket.normalizeUploadRelation(relationObject, included);
    ticketRelationships.watchers = Ticket.normalizeUsersRelation(relationObject, included, 'watchers');

    return ticketRelationships;
  }

  private static normalizeAccountRelation(relationObject: any, included: Object[]): RelationshipData<AccountRelationAttribute> | undefined {
    let accountRelation = new RelationshipData<AccountRelationAttribute>();
    if (!relationObject?.account?.data?.id) return undefined;

    accountRelation.id = +relationObject.account.data.id;
    accountRelation.type = relationObject.account.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == accountRelation.type &&
        includedObject.id == accountRelation.id
    });
    accountRelation.attributes = (tmp as IncludedData<AccountRelationAttribute>).attributes

    return accountRelation;
  }

  private static normalizeUserRelation(relationObject: any, included: Object[], key: string): RelationshipData<UserRelationAttribute> | undefined {
    let userRelation = new RelationshipData<UserRelationAttribute>();
    if (!relationObject[key]?.data?.id) return undefined;

    userRelation.id = +relationObject[key].data.id;
    userRelation.type = relationObject[key].data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == userRelation.type &&
        includedObject.id == userRelation.id
    });
    userRelation.attributes = (tmp as IncludedData<UserRelationAttribute>).attributes

    return userRelation;
  }

  private static normalizeIssue_typeRelation(relationObject: any, included: Object[]): RelationshipData<Issue_typeRelationAttribute> | undefined {
    let issue_typeRelation = new RelationshipData<Issue_typeRelationAttribute>();
    if (!relationObject?.issue_type?.data?.id) return undefined;

    issue_typeRelation.id = +relationObject.issue_type.data.id;
    issue_typeRelation.type = relationObject.issue_type.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == issue_typeRelation.type &&
        includedObject.id == issue_typeRelation.id
    });
    issue_typeRelation.attributes = (tmp as IncludedData<Issue_typeRelationAttribute>).attributes

    return issue_typeRelation;
  }

  private static normalizePriorityRelation(relationObject: any, included: Object[]): RelationshipData<PriorityRelationAttribute> | undefined {
    let priorityRelation = new RelationshipData<PriorityRelationAttribute>();
    if (!relationObject?.priority?.data?.id) return undefined;

    priorityRelation.id = +relationObject.priority.data.id;
    priorityRelation.type = relationObject.priority.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == priorityRelation.type &&
        includedObject.id == priorityRelation.id
    });
    priorityRelation.attributes = (tmp as IncludedData<PriorityRelationAttribute>).attributes

    return priorityRelation;
  }

  private static normalizeRequest_typeRelation(relationObject: any, included: Object[]): RelationshipData<Request_typeRelationAttribute> | undefined {
    let request_typeRelation = new RelationshipData<Request_typeRelationAttribute>();
    if (!relationObject?.request_type?.data?.id) return undefined;

    request_typeRelation.id = +relationObject.request_type.data.id;
    request_typeRelation.type = relationObject.request_type.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == request_typeRelation.type &&
        includedObject.id == request_typeRelation.id
    });
    request_typeRelation.attributes = (tmp as IncludedData<Request_typeRelationAttribute>).attributes

    return request_typeRelation;
  }

  private static normalizeStatusRelation(relationObject: any, included: Object[]): RelationshipData<StatusRelationAttribute> | undefined {
    let statusRelation = new RelationshipData<StatusRelationAttribute>();
    if (!relationObject?.status?.data?.id) return undefined;

    statusRelation.id = +relationObject.status.data.id;
    statusRelation.type = relationObject.status.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == statusRelation.type &&
        includedObject.id == statusRelation.id
    });
    statusRelation.attributes = (tmp as IncludedData<StatusRelationAttribute>).attributes

    return statusRelation;
  }

  private static normalizeUploadRelation(relationObject: any, included: Object[]): RelationshipData<UploadRelationAttribute>[] | undefined {
    if (!(relationObject?.uploads?.data?.length > 0)) return undefined;

    return relationObject.uploads.data.map((upload: any) => {
      let uploadRelation = new RelationshipData<UploadRelationAttribute>();

      uploadRelation.id = +upload.id;
      uploadRelation.type = upload.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == uploadRelation.type &&
          includedObject.id == uploadRelation.id
      });
      uploadRelation.attributes = (tmp as IncludedData<UploadRelationAttribute>).attributes
      return uploadRelation;
    })
  }

  private static normalizeUsersRelation(relationObject: any, included: Object[], key: string): RelationshipData<UserRelationAttribute>[] | undefined {
    if (!(relationObject[key]?.data?.length > 0)) return undefined;

    return relationObject[key].data.map((data: any) => {
      let userRelation = new RelationshipData<UserRelationAttribute>();

      userRelation.id = +data.id;
      userRelation.type = data.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == userRelation.type &&
          includedObject.id == userRelation.id
      });
      userRelation.attributes = (tmp as IncludedData<UserRelationAttribute>).attributes
      return userRelation;
    })
  }
}