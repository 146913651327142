<nz-layout class="assets-layout" *ngIf="invoice">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'invoices']">Invoices</a></nz-breadcrumb-item>
        <nz-breadcrumb-item *ngIf="invoice?.attributes?.uniq_number">{{ invoice.attributes.uniq_number }}</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span routerLink=".." class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span class="txt-grey"> Back</span></span>
      </div>
    </div>
    <div nz-row class="invoice-head">
      <div nz-col nzSpan="12">
        <h1>Invoice</h1>
      </div>
      <div nz-col nzSpan="12" class="text-right">
        <a [routerLink]="['/app', 'invoices', invoice.id, 'support']" class="request-support">
          Billing Support <span nz-icon nzType="question-circle" [nzTheme]="'outline'"></span>
        </a>
      </div>
    </div>

    <div class="invoice-container">
      <div nz-row>
        <div nz-col nzSpan="24" class="empist-invoice-details">
          <div class="empist-address">
            <strong>EMPIST, LLC</strong><br/>
            116 N.YORK STREET, SUITE 210<br/>
            ELMHURST, IL 60126 US<br/>
            312-957-7458<br/>
            billing&#64;empist.com<br/>
            www.empist.com
          </div>
          <div class="empist-logo">
            <img src="../../../assets/images/empist-logo.svg" alt="EMPIST"/>
          </div>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="24" class="invoice-top-info">
          <div class="account-name-address">
            <strong>BILL TO</strong>
            {{ invoice.relationships?.account?.attributes?.title }}
          </div>
          <div class="invoice-information">
            <div class="invoice-top-item">
              <div class="item-label">Invoice#:</div>
              <div class="item-value">{{ invoice.attributes.uniq_number }}</div>
            </div>
            <div class="invoice-top-item">
              <div class="item-label">Date:</div>
              <div class="item-value">{{ invoice.attributes.date_created | date }}</div>
            </div>
            <div class="invoice-top-item">
              <div class="item-label">Amount due:</div>
              <div class="item-value">{{ invoice.attributes.invoice_balance | currency }}</div>
            </div>
            <div class="invoice-top-item">
              <div class="item-label" *ngIf="invoice.attributes.terms_code">Terms:</div>
              <div class="item-value">{{ invoice.attributes.terms_code }}</div>
            </div>
          </div>
        </div>
      </div>

      <div nz-row *ngIf="invoice.attributes.due_date" class="invoice-due-date">
        <div nz-col nzSpan="24">
          <span class="due-date-label">Due Date</span>
        </div>
        <div nz-col nzSpan="24" class="due-date-value">
          {{ invoice.attributes.due_date | date }}
        </div>
      </div>

      <div nz-row class="invoice-table">
        <div nz-col nzSpan="24">
          <div nz-row class="list-head">
            <div nz-col nzSpan="7" [nzSm]="7">Item</div>
            <div nz-col nzSpan="8" [nzSm]="8">Description</div>
            <div nz-col nzSpan="3" [nzSm]="3" class="text-right">Quantity</div>
            <div nz-col nzSpan="3" [nzSm]="3" class="text-right">Price</div>
            <div nz-col nzSpan="3" [nzSm]="3" class="text-right">Amount</div>
          </div>
          <div nz-row class="list-row"
               *ngFor="let line_item of invoice.relationships?.line_items">
            <div nz-col nzSpan="7" [nzSm]="7">{{ line_item.attributes.title }}</div>
            <div nz-col nzSpan="8" [nzSm]="8">{{ line_item.attributes.description ? line_item.attributes.description : '-' }}</div>
            <div nz-col nzSpan="3" [nzSm]="3" class="text-right">{{ line_item.attributes.quantity ? +line_item.attributes.quantity : '-' }}</div>
            <div nz-col nzSpan="3" [nzSm]="3" class="text-right">{{ line_item.attributes.rate | currency }}</div>
            <div nz-col nzSpan="3" [nzSm]="3" class="text-right">{{ line_item.attributes.amount | currency }}</div>
          </div>
        </div>
      </div>
      <div class="totals">
        <div class="totals-row">
          <div class="total-label">Subtotal:</div>
          <div class="total-value">{{ subtotal | currency }}</div>
        </div>
        <div class="totals-row">
          <div class="total-label">Total:</div>
          <div class="total-value">{{ invoice.attributes.total_amount | currency }}</div>
        </div>
        <div class="totals-row">
          <div class="total-label">Amount due:</div>
          <div class="total-value">{{ invoice.attributes.invoice_balance | currency }}</div>
        </div>
      </div>
      <div nz-row *ngIf="invoice.attributes.message_on_invoice" class="invoice-message">
        <div nz-col nzSpan="24">
          {{ invoice.attributes.message_on_invoice }}
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
