<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a>Services</a></nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','solutions']" class="back-button">
          <i nz-icon nzType="left" [nzTheme]="'outline'"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24" class="installed-services">
        <div nz-row nzJustify="center">
          <div nz-col>
            <h1>Services</h1>
          </div>
        </div>
        <div nz-row class="installed-services-top">
          <div nz-col nzSpan="24">
            <div nz-row>
              <div nz-col class="installed-services-top-text">
                Here is a comprehensive list audit of all services, categorized by account and accompanied by their corresponding quantities.
              </div>
            </div>
            <br/>
            <div nz-row>
              <div nz-col nzSpan="24">
                <div class="statistics" *ngIf="!syncDataLoading && !loading">
                  <div class="statistic" *ngFor="let item of lastSyncNormalizedData">
                    <span class="statistic-label">{{ item.title }} <span>(last sync)</span></span>
                    <span class="statistic-value">{{ item.date | date:'MMM d YY, hh:mm' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!loading" class="search-container">
          <div class="search-by">Services By Account</div>
          <nz-input-group [nzPrefix]="prefixIconSearch">
            <input nz-input placeholder="Type here to search" [(ngModel)]="accountSearchInput" (ngModelChange)="searchAccounts()" />
          </nz-input-group>
          <ng-template #prefixIconSearch>
            <span nz-icon nzType="search"></span>
          </ng-template>
        </div>

        <div nz-row *ngIf="loading" nzJustify="center">
          <div nz-col nzSpan="24" class="data-loading">
            <nz-spin nzSimple></nz-spin>
          </div>
        </div>

        <div class="list-head">
          <div class="account-name">Account Name</div>
          <div *ngIf="!loading" class="include-changes">
            <label nz-checkbox [(ngModel)]="showOnlyAccountsWithChanges" (ngModelChange)="toggleRequiresReview()">
              Requires review
            </label>
          </div>
        </div>

        <ng-container *ngFor="let accountData of filteredAccountArrayMap">
          <div class="account-row"
               [ngClass]="{ 'has-changes': getUnreviewedServices(accountData[1].installedServices) > 0 }">
            <div class="account-title"
                 [ngClass]="{'open': accountData[1].open}"
                 (click)="toggleAccountServices(+accountData[0])">
              <span class="account-title-cnt">{{ accountData[1].title }}</span>
              <span class="changes-count" *ngIf="getUnreviewedServices(accountData[1].installedServices) > 0">Changes: {{ getUnreviewedServices(accountData[1].installedServices) }}</span>
              <span class="services-count" *ngIf="false">Services<span *ngIf="false">: {{ accountData[1].installedServices.length }}</span></span>
              <span class="services-toggle" *ngIf="!accountData[1].open" nz-icon nzType="down" [nzTheme]="'outline'"></span>
              <span class="services-toggle" *ngIf="accountData[1].open" nz-icon nzType="up" [nzTheme]="'outline'"></span>
            </div>
            <div class="account-installed-services" *ngIf="accountData[1].open">
              <div *ngIf="accountData[1].loading" class="data-loading">
                <nz-spin nzSimple></nz-spin>
              </div>
              <ng-container *ngIf="!accountData[1].loading">
                <ng-container *ngFor="let installedService of accountData[1].installedServices">
                  <div class="account-installed-service" [ngClass]="{'has-change': !installedService.reviewed}">
                    <div class="service-row">
                      <div class="service-logo"><img [src]="getLogoByServiceId(installedService.service.id)" [alt]="installedService.title"/></div>
                      <div class="service-title">{{ installedService.title }}</div>
                      <div class="service-metrics-container">
                        <div class="installed-service-detail-metrics" *ngIf="installedService.detailMetrics.length">
                          <ng-container *ngFor="let detailMetric of installedService.detailMetrics">
                            <div class="installed-service-metric" *ngIf="getCountMetricValueFromDetailMetric(detailMetric, installedService) > 0">
                              <span *ngIf="openMetricAccountId != +accountData[0] || openDetailsInstalledServiceId != installedService.id || openDetailsMetricId != detailMetric.id" nz-icon nzType="down-circle" [nzTheme]="'outline'"></span>
                              <span *ngIf="openMetricAccountId == +accountData[0] && openDetailsInstalledServiceId == installedService.id && openDetailsMetricId == detailMetric.id" nz-icon nzType="up-circle" [nzTheme]="'outline'"></span>
                              <span class="service-values-toggle" (click)="toggleMetricDetails(installedService.id, installedService.service, detailMetric.id, +accountData[0])">
                                {{ getCountMetricValueFromDetailMetric(detailMetric, installedService) }} {{ detailMetric.title }}
                              </span>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="service-change-status">
                        <ng-container *ngIf="!installedService.reviewed && canShowAcknowledgeButton(installedService)">
                          <button nz-button nzShape="round" class="btn-blue" (click)="updateInstalledService(+accountData[0], installedService.id)">Acknowledge</button>
                        </ng-container>
                      </div>
                    </div>

                    <div *ngIf="isInstalledServiceOpen(+accountData[0], installedService)">
                      <div *ngIf="!loading && staticFilterOptions.length" class="filter-items">
                        <div *ngFor="let item of staticFilterOptions"
                            class="filter-item"
                            [ngClass]="{'active': selectedFilterOption.label == item.label}"
                            (click)="applyStaticFilter(item)">
                          {{ item.label }} ({{ item.count }}) <ng-container *ngIf="item.deletedCount && item.deletedCount > 0"> - Deleted ({{ item.deletedCount}})</ng-container>
                        </div>
                      </div>

                      <div nz-row *ngIf="detailsLoading">
                        <div nz-col nzSpan="24" class="data-loading">
                          <nz-spin nzSimple></nz-spin>
                        </div>
                      </div>
                      <div *ngIf="!detailsLoading && openMetricDetails.length"
                          class="export-row">
                        <div class="changes-toggle" *ngIf="openServiceDetailsHasChanges || openServiceDetailsHasMarkedForDelete">
                          <div class="action-label">Show:</div>
                          <div class="action" [ngClass]="{'active': showChangedSelection == 'all'}" (click)="showAll()">All</div>
                          <div class="action" [ngClass]="{'active': showChangedSelection == 'changes'}" (click)="showOnlyChanged()" *ngIf="openServiceDetailsHasChanges">Changes</div>
                          <div class="action" [ngClass]="{'active': showChangedSelection == 'mark-for-deletion'}" (click)="showOnlyMarkedForDelete()" *ngIf="openServiceDetailsHasMarkedForDelete">Marked for Removal</div>
                        </div>
                        <button nz-button nzShape="round" class="btn-blue" (click)="exportToExcel()">
                          <span nz-icon nzType="file-excel" [nzTheme]="'outline'"></span>
                          Export
                        </button>
                      </div>

                      <nz-table #installedServiceValuesTableData
                                *ngIf="!detailsLoading"
                                [nzData]="openServiceFilteredDetails"
                                [nzPageSize]="20"
                                [nzHideOnSinglePage]="true"
                                [nzOuterBordered]="false"
                                [nzBordered]="false"
                                nzHideOnSinglePage
                                nzShowSizeChanger
                                [nzPageSizeOptions]="[20,50,100]"
                                [nzTotal]="openMetricDetails.length"
                                class="installed-service-details-table">
                        <thead>
                        <tr>
                          <th *ngFor="let column of columns"
                              [nzSortFn]="column.sortFn"
                              [(nzSortOrder)]="column.sortOrder">{{ column.title }}</th>
                          <th *ngIf="installedService.service?.attributes?.system_key == 'digcore-users'">Services</th>
                          <th *ngIf="installedService.service?.attributes?.system_key == 'digcore-users'">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let row of installedServiceValuesTableData.data">
                            <tr [ngClass]="{
                              'changed': row.attributes['reviewed'] === false,
                              'deleted': !!row.attributes['deleted_at'],
                              'marked-for-delete': !!row.attributes.mark_for_deletion && !row.attributes['deleted_at'] && row.attributes['reviewed'] !== false
                              }">
                              <td *ngFor="let attr of keys" [innerHTML]="displayValue(row, attr)"></td>
                              <td *ngIf="installedService.service?.attributes?.system_key == 'digcore-users'" class="cell-services" (click)="getUserServices(row['id'])">
                                <nz-collapse [nzBordered]="false" [nzGhost]="false">
                                  <nz-collapse-panel [nzHeader]="extraTpl" [nzShowArrow]="false">
                                    <div *ngFor="let serviceTitle of userServices[row['id']]">
                                      {{ serviceTitle }}
                                    </div>
                                  </nz-collapse-panel>
                                </nz-collapse>
                                <ng-template #extraTpl>
                                  <div *ngIf="userServices[row['id']]">
                                  </div>
                                  <div *ngIf="!userServices[row['id']]">
                                    <nz-spin nzSimple *ngIf="userServiceLoading == row['id']"></nz-spin>
                                    <span *ngIf="userServiceLoading != row['id']">
                                      Show Services <span nz-icon nzType="caret-down" nzTheme="outline"></span>
                                    </span>
                                  </div>
                                </ng-template>
                              </td>
                              <td *ngIf="installedService.service?.attributes?.system_key == 'digcore-users'">
                                <a [href]="'https://empist.deskware.com/people/'+row['id']+'/contacts'" target="_blank" class="user-link">
                                  <dw-icon type="dw:openInNewTab"></dw-icon>
                                </a>
                              </td>
                            </tr>
                          </ng-container>

                          <tr *ngIf="installedService.title == 'EMPIST Cloud' && openDetailsMetricId == 12" class="summary-row">
                            <td><strong>Total vCPUs and vRAM</strong></td>
                            <td>{{ totalCpu }}</td>
                            <td>{{ totalRamInMB }} MB</td>
                          </tr>
                        </tbody>
                      </nz-table>
                      <div class="details-info" *ngIf="openServiceDetailsHasChanges || openServiceDetailsHasMarkedForDelete">
                        <div class="details">
                          <div class="added-items" *ngIf="openServiceDetailsHasChanges">Items Added</div>
                          <div class="removed-items" *ngIf="openServiceDetailsHasChanges">Items Removed</div>
                          <div class="marked-for-delete-items" *ngIf="openServiceDetailsHasMarkedForDelete">Marked for Removal</div>
                        </div>
                      </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        </ng-container>
      </div>
    </div>
  </nz-content>
</nz-layout>
