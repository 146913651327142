import { Component, DestroyRef, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UsersService } from "src/app/services/users.service";
import { TicketsService } from "src/app/services/tickets.service";
import { ConversationsService } from "src/app/services/conversations.service";
import { Ticket } from "src/app/utilities/models/ticket/ticket";
import { ActivatedRoute, Params } from "@angular/router";
import { TicketChageStatusDto } from "src/app/utilities/models/dto/ticketChageStatusDto";
import { NzMessageService } from "ng-zorro-antd/message";
import { BookingUrl } from "src/app/utilities/models/ticket/bookingUrl";
import { User } from "src/app/utilities/models/user/user";
import { LoaderService } from "src/app/services/loader.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { BookMeetingSelectionComponent } from "./bookMeetingSelection/bookMeetingSelection.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'ticketPage',
  templateUrl: './ticketPage.component.html',
  styleUrls: ['./ticketPage.component.scss']
})
export class TicketPageComponent implements OnInit, OnDestroy {
  @ViewChild('ticketDescription') descriptionElementView: ElementRef;
  descriptionHeight: number;
  ticket?: Ticket;
  activeConversationId:number | undefined;
  showFullDescription: boolean = false;
  expandDescriptionButtonText: string = 'More';
  bookingUrls: BookingUrl[];
  loggedInUser: User;
  loaderVisible: boolean = false;
  showActionModal: boolean = false;
  actionsPopoverVisible: boolean = false;

  constructor(private userService: UsersService,
              private ticketService: TicketsService,
              private msg: NzMessageService,
              private conversationService: ConversationsService,
              private activatedRoute: ActivatedRoute,
              private loaderService: LoaderService,
              private modal: NzModalService,
              private destroyRef: DestroyRef) {
  }

  ngOnInit() {
    this.loggedInUser = this.userService.loggedInUser;
    const permissionBookingUrl = this.userService.findPermission('Ticketing::Ticket', 'ticketing/operator/v1/tickets', 'booking_url');
    this.activatedRoute.params.subscribe({
        next: (params: Params) => {
          this.showTicket(params['id']);
        }
      }
    );
    this.ticketService.ticket
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (ticketResponse: Ticket) => {
          this.ticket = ticketResponse;
          if(permissionBookingUrl) {
            this.getBookingUrls();
          }
        }
      });

    this.conversationService.activeConversationIdSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (conversationId: number | undefined) => {
          this.activeConversationId = conversationId;
        }
      });

    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(value: boolean) => {
          this.loaderVisible = value;
          this.showActionModal = value;
          this.setActionsPopoverVisible(false);
        }
      })
  }

  ngOnDestroy() {
    this.ticketService.resetActiveTicketBookingUrls();
  }

  showTicket(code: string) {
    this.ticketService.getTicketByCode(code);
  }

  toggleFullDescription(){
    this.showFullDescription = !this.showFullDescription;
    this.expandDescriptionButtonText = (!this.showFullDescription) ? 'More' : 'Less';
  }

  performTicketAction(status_key: string) {
    if(!this.ticket?.attributes?.code) return;

    const available_status_keys = ['completed_status','callback_status','escalate_status'];
    if(!available_status_keys.includes(status_key)) return;

    const payload = new TicketChageStatusDto(this.ticket.id, "tickets", status_key);
    this.loaderService.setLoadingText('Processing your request');
    this.loaderService.setLoadingSecondaryText('');
    switch(status_key){
      case 'completed_status':
        this.loaderService.setLoadedText('Ticket has been completed!');
        this.loaderService.setLoadedSecondaryText('Thank you');
        break;
      case 'callback_status':
        this.loaderService.setLoadedText('A callback request<br/>has been submitted!');
        this.loaderService.setLoadedSecondaryText('One of our agents will be in touch with you soon');
        break;
      case 'escalate_status':
        this.loaderService.setLoadedText('Your ticket has been escalated!');
        this.loaderService.setLoadedSecondaryText('Thank you');
        break;
    }
    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.ticketService.changeStatus(this.ticket.attributes.code, payload).subscribe({
      next:( _ : any) => {
        this.loaderService.setProcessing(false);
        setTimeout(() => {
          this.loaderService.setLoaderVisible(false);
        }, 5000)
      }
    });
  }

  getBookingUrls() {
    if(!this.ticket?.attributes.code) return;

    this.ticketService.getBookingUrl(this.ticket.attributes.code)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(response) => {
          if(response?.data) {
            this.bookingUrls = response.data.map((bookingUrl:any) => new BookingUrl(bookingUrl, [])).filter((bookingUrl:BookingUrl) => bookingUrl.attributes.booking_url);
            this.ticketService.setActiveTicketBookingUrls(this.bookingUrls);
          }
        }
      })
  }

  showBookMeetingModal() {
    this.createBookMeetingModal();
    this.setActionsPopoverVisible(false);
  }

  setActionsPopoverVisible(value: boolean){
    this.actionsPopoverVisible = value;
  }

  createBookMeetingModal() {
    this.modal.create({
      nzTitle: '',
      nzFooter: null,
      nzClassName: 'book-meeting',
      nzMaskClosable: false,
      nzClosable: true,
      nzContent: BookMeetingSelectionComponent,
    });
  }
}
