<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'support']">Support</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'support', 'tickets']">Tickets</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>{{ ticket?.attributes?.code }}</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row>
      <div nz-col nzSpan="24">
        <div nz-row class="ticket-top">
          <div nz-col nzSpan="10">
            <span [routerLink]="['/app', 'support', 'tickets']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span class="txt-grey"> Back</span></span>
          </div>
          <div nz-col nzSpan="14">
            <span>
              <button nz-button
                      class="ticket-actions-btn black-round"
                      nz-popover
                      [nzPopoverVisible]="actionsPopoverVisible && !showActionModal"
                      [nzPopoverContent]="ticketActionsTemplate"
                      nzPopoverTrigger="click"
                      nzPopoverPlacement="bottomRight"
                      (click)="setActionsPopoverVisible(true)">
                Ticket Actions <span nz-icon nzType="caret-down" [nzTheme]="'outline'"></span>
              </button>
            </span>

            <ng-template #ticketActionsTemplate>
              <a (click)="performTicketAction('completed_status')">Close Request</a>
              <a (click)="performTicketAction('callback_status')">Request Status Update</a>
              <a (click)="performTicketAction('escalate_status')">Request Manager Assistance</a>
              <nz-divider class="actions-divider" *ngIf="bookingUrls"></nz-divider>
              <a (click)="showBookMeetingModal()" *ngIf="bookingUrls">Book a meeting</a>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div nz-row>
      <div nz-col
           [nzXs]="{ span: 24, offset: 0 }"
           [nzSm]="{ span: 24, offset: 0 }"
           [nzMd]="{ span: 24, offset: 0 }"
           [nzLg]="{ span: 24, offset: 0 }"
           [nzXl]="{ span: 24, offset: 0 }"
           [nzXXl]="{ span: 24, offset: 0 }">
        <div nz-row>
          <div nz-col nzSpan="24">
            <div class="ticket-title">{{ ticket?.attributes?.subject }}</div>
          </div>

        </div>
        <div nz-row [nzGutter]="[0,30]">
          <div nz-col>
            <h1 class="ticket-details">by {{ ticket?.relationships?.creator?.attributes?.fullname }} on {{ticket?.attributes?.created_at | date:'medium'}}</h1>
            <strong title="Status">{{ ticket?.relationships?.status?.attributes?.title }}</strong>
          </div>
        </div>
        <div nz-row [nzGutter]="[0,30]" *ngIf="ticket?.attributes?.description">
          <div nz-col class="ticket-description">
            <div [innerHtml]="ticket?.attributes?.description" class="full-description-hidden" #ticketDescription></div>
            <div [innerHtml]="ticket?.attributes?.description" [class]="(showFullDescription) ? 'full-description' : 'limited-description'"></div>
            <a class="more" (click)="toggleFullDescription()" *ngIf="ticketDescription.offsetHeight > 88">{{expandDescriptionButtonText}}</a>
          </div>
        </div>
        <div nz-row class="ticket-options-container">
          <div nz-col nzSpan="24">
            <ticket-options></ticket-options>
          </div>
        </div>
      </div>
    </div>

    <custom-loader *ngIf="loaderVisible"></custom-loader>
  </nz-content>
  <nz-footer *ngIf="ticket && ticket.id > 0 && activeConversationId && !ticket.attributes.merged_into_id">
    <commentForm [ticketId]="ticket.id"
                 [conversationId]="activeConversationId"
                 *ngIf="activeConversationId">
    </commentForm>
  </nz-footer>
</nz-layout>
