import { Component, OnInit } from "@angular/core";
import { BookingUrl } from "src/app/utilities/models/ticket/bookingUrl";
import { TicketsService } from "src/app/services/tickets.service";
import { Router } from "@angular/router";
import { NzModalRef } from "ng-zorro-antd/modal";

@Component({
  selector: 'app-book-meeting-selection',
  templateUrl: './bookMeetingSelection.component.html',
  styleUrls: ['./bookMeetingSelection.component.scss']
})
export class BookMeetingSelectionComponent implements OnInit {
  selectedBookingUrl: BookingUrl;
  bookingUrls: BookingUrl[];
  ticketCode: string;

  constructor(private modal: NzModalRef,
              private ticketService: TicketsService,
              private router: Router) {
  }

  ngOnInit() {
    this.ticketCode = this.ticketService.activeTicket?.attributes?.code;
    this.bookingUrls = this.ticketService.activeTicketBookingUrls;
    this.selectedBookingUrl = this.bookingUrls[0];
  }

  goToBookMeeting(bookingUrl: BookingUrl) {
    if(!bookingUrl?.attributes?.booking_url) return;

    this.ticketService.setBookingUrl(bookingUrl.attributes.booking_url);
    this.router.navigate(['/app','book-meeting']).then( _ => this.destroyModal());
  }

  destroyModal(): void {
    this.modal.destroy();
  }

}