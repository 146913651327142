import { Component, DestroyRef, OnInit } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, switchMap } from "rxjs";
import { InvoicesService } from "src/app/services/invoices.service";
import { UsersService } from "src/app/services/users.service";
import { Invoice } from "src/app/utilities/models/invoice/invoice";
import { debounceTime } from "rxjs/operators";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { QueryParam } from "src/app/utilities/models/parameters/queryParam/queryParam";
import { SavedQueryParam } from "src/app/utilities/models/invoice/SavedQueryParams";
import { User } from "src/app/utilities/models/user/user";
import { InvoiceStatus } from "src/app/utilities/models/invoice/invoiceStatus";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DBExportPayload } from 'src/app/utilities/models/dto/dbExportPayloadDto';
import { NzMessageService } from "ng-zorro-antd/message";
import { FormGroup } from '@angular/forms';
import { FilterAggregationOption } from 'src/app/utilities/models/filters/filterAggregationOption';
import { FilterDropdownOption } from 'src/app/utilities/models/filters/filterDropdownOption';
import { FiltersService } from 'src/app/services/filters.service';
import { AvailableFilterDetail } from 'src/app/utilities/models/filters/availableFilterDetail';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
  accountId: number | undefined;
  invoices: Invoice[];
  loading: boolean = false;
  exportLoading: boolean = false;
  pageIndex: number = 1;
  pageSize: number = 20;
  total: number = 0;
  totalPages: number = 0;
  searchString: string = '';
  searchSubject$ = new BehaviorSubject<string>('');
  currentQueryParams: QueryParam[] = [];
  loggedInUser: User;
  activeInvoiceView: SavedQueryParam;
  invoiceViews: SavedQueryParam[] = [];

  static filterLabel: { [key: string]: string } = {
    'uniq_number': 'Invoice number contains',
    'account.id': 'account is',
    'invoice_status.id': 'status is',
  };

  filtersFormGroup: FormGroup;
  filtersCountFormGroup: number = 0;
  filterSelectOptionsByKey: { [key: string]: FilterAggregationOption[] } = {}
  addFilterEnabled: boolean = true;
  availableFilterOptions: FilterDropdownOption[] = [];

  static invoiceViews: SavedQueryParam[] = [
    new SavedQueryParam('All', []),
  ];

  constructor(private invoiceService: InvoicesService,
              private filterService: FiltersService,
              private userService: UsersService,
              private destroyRef: DestroyRef,
              private msg: NzMessageService) {}

  ngOnInit() {
    this.filterService.resetFilters();
    this.filtersFormGroup = new FormGroup({});
    this.invoiceViews = [...InvoiceListComponent.invoiceViews];
    this.accountId = this.userService.loggedInUser?.relationships?.account?.id;
    this.loggedInUser = this.userService.loggedInUser;
    this.getAvailableFilters();
    this.getInvoiceStatuses();
    this.getInvoices();
    this.activeInvoiceView = this.invoiceViews[0];

    this.filterService.selectedReportParamsChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          this.getInvoices();
          this.getAvailableFilters();
          this.calculateAllSelectControlsOptions(value);
        }
      })

    this.initializeControlsByReportParams();
  }

  getAvailableFilters() {
    this.filterService.getAvailableFilters('asset_resources', 'index', 'invoice_list')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ((value: FilterDropdownOption[]) => {
            this.availableFilterOptions = value;
          }
        )
      });
  }


  onPageIndexChange(indexNumber: number) {
    this.pageIndex = indexNumber;
    this.getInvoices(this.currentQueryParams, this.pageSize, this.pageIndex);
  }

  onPageSizeChange(sizeNumber: number) {
    this.pageIndex = 1
    this.pageSize = sizeNumber;
    this.getInvoices(this.currentQueryParams, this.pageSize, this.pageIndex);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'date_created';
    const sortOrder = (currentSort && currentSort.value) || 'descend';
    this.getInvoices(this.currentQueryParams, params.pageSize, params.pageIndex, sortField, sortOrder);
  }

  searchInvoices() {
    if(this.searchString.length == 0 || this.searchString.length > 2) {
      this.searchSubject$.next(this.searchString);
    }
  }

  applyInvoiceView(invoiceView: SavedQueryParam) {
    this.pageIndex = 1;
    this.searchString = '';
    this.getInvoices(invoiceView.queryParams);
    this.activeInvoiceView = invoiceView;

    invoiceView.queryParams.forEach((queryParam: QueryParam) => {
      let key = queryParam.key.replace('_id','.id').replace('_eq','');
      const filter: AvailableFilterDetail | null = this.filterService.getFilterDetails(key);
    if (!filter) return;

      if(filter) {
        this.addFilterControl(key, queryParam.value);
        this.onSelect(queryParam.value, filter);
      }
    })
  }

  getInvoiceViewData(invoiceView: SavedQueryParam) {
    invoiceView.loading = true;
    this.invoiceService.getInvoicesTotalCount(invoiceView.queryParams)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          invoiceView.loading = false;
          invoiceView.totalCount = response.meta.total_count || 0;
        }
      })
  }

  exportInvoices() {
    const payload: DBExportPayload = this.prepareExportPayload();
    const msgId = this.msg.loading('Request in progress').messageId;
    this.exportLoading = true;
    this.invoiceService.exportInvoices(payload)
      .subscribe({
        next: (response: any) => {
          this.msg.remove(msgId);
        },
        error: (error: Error) => { console.log(error) },
        complete: () => {
          this.exportLoading = false;
          this.msg.success('We have started processing your file.<br/>An email will be sent to you directly with download link.<br/>Please give it up to 5 minutes to send.<br/>If you have any issues contact your administrator or EMPIST support.', { nzDuration: 10000 })
        }
      });
  }

  private getInvoicesViews() {
    this.invoiceViews.forEach((invoiceView: SavedQueryParam) => {
      this.getInvoiceViewData(invoiceView);
    });
  }

  private getInvoiceStatuses(){
    this.invoiceService.getInvoiceStatuses()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.setInvoiceViews(response);
          this.getInvoicesViews();
        }
      })
  }

  private setInvoiceViews(response: any){
    response.data.forEach((row: any) => {
      const invoiceStatus = new InvoiceStatus(row, response.included);
      this.invoiceViews.push(
        new SavedQueryParam(invoiceStatus.attributes.title, [new QueryParam('invoice_status_id_eq', invoiceStatus.id.toString())])
        )
    });
  }

  private getInvoices(queryParams: QueryParam[] = [],pageSize: number = this.pageSize, pageIndex: number = 1, sortField: string = 'date_created', sortOrder: string = 'descend') {
    const params = this.filterService.getSelectedFilters();
    const filterQueryParams = params.map(param => {
      const val = Array.isArray(param.value) ? param.value[0] ?? '' : param.value ?? '';
      let operator = '_eq';
      if(param.operator[0] == 'like') {
        operator = '_cont';
      }

      return new QueryParam(param.key.replace('.','_')+operator, val.toString());
    })
    if(this.accountId) {
      this.loading = true;
      // queryParams.unshift(new QueryParam('account_id_eq', this.accountId.toString()));
      this.currentQueryParams = queryParams;
      const queryParameters = filterQueryParams.concat(queryParams);

      this.invoiceService.get_invoices(queryParameters, pageSize, pageIndex, sortField, sortOrder, this.searchString)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: any) => {
            this.invoices = response.data.map((item: any) => new Invoice(item, response.included));
            this.total = response.meta.total_count ?? 0;
            this.totalPages = response.meta.total_pages;
            this.loading = false;

            this.checkIfAddFilterIsEnabled();
            return response;
          },
          error: () => {
            this.loading = false;
          }
        })
    }

    return [];
  }

  private prepareExportPayload() {
    const queryParams = this.currentQueryParams;
    if(this.searchString.length){
      queryParams.push({key: 'uniq_number_cont', value: this.searchString });
    }

    const payload: DBExportPayload = {
      export_headers: "id,account.title,invoice_status.title,uniq_number,date_created,due_date,total_amount,invoice_balance,message_on_invoice,terms_code",
      from: "invoices,index",
      format: "xls",
      include: "account,invoice_status"
    }
    queryParams.forEach((queryParam: QueryParam) => {
      payload[`q[${queryParam.key}]`] = queryParam.value;
    })

    return payload;
  }

  addFilterControl(key: string, selectedValue: any = null) {
    const filter: AvailableFilterDetail | null = this.filterService.getFilterDetails(key);
    if (!filter) return;
    if(!this.filtersFormGroup.controls[key]) {
      this.filtersFormGroup.addControl(key, AvailableFilterDetail.getFormControlByType(filter.type));
      this.filtersCountFormGroup++;
      this.getOptions(filter);
    }
    this.filtersFormGroup.controls[key].setValue(selectedValue);
    this.checkIfAddFilterIsEnabled();
  }

  removeFilterControl(filter: AvailableFilterDetail) {
    this.filtersFormGroup.removeControl(filter.computed_key);
    this.filtersCountFormGroup--;
    this.filterService.removeFilterFromSelectedParams(filter);
    this.checkIfAddFilterIsEnabled();
  }

  clearAllFilters(notifyChange: boolean = true) {
    this.filtersFormGroup = new FormGroup([]);
    this.filterService.clearAllFilters(notifyChange);
    this.filtersCountFormGroup = 0;
    this.checkIfAddFilterIsEnabled();
  }

  getFilterLabel(key: string, label: string): string {
    return InvoiceListComponent.filterLabel[key] || label;
  }

  getFiltersByFormGroupControls(): AvailableFilterDetail[] {
    return Object.keys(this.filtersFormGroup.controls).map((key: string) => {
      return this.filterService.getFilterDetails(key);
    })
  }

  onSelect(value: any, filter: AvailableFilterDetail) {
    this.pageIndex = 1
    if (value?.length == 0) {
      this.filterService.removeFilterFromSelectedParams(filter);
    } else {
      switch (filter.type) {
        case 'text':
        case 'string':
          if (value?.length > 2)
            this.filterService.putFilterToSelectedParams(filter, value);
          break;
        case 'date': // handle date as simple dates without time
          const dates = [new Date(value[0]).toISOString().slice(0, 10), new Date(value[1]).toISOString().slice(0, 10)];
          this.filterService.putFilterToSelectedParams(filter, dates);
          break;
        default:
          this.filterService.putFilterToSelectedParams(filter, value);
      }
    }
  }

  getOptions(filter: AvailableFilterDetail, str?:string): void {
    if(!filter.action) return;

    this.filterService.getFilterOptions(filter.action, str)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: (response: any) => {
        this.filterSelectOptionsByKey[filter.computed_key] = response.data.map((row: any) => new FilterAggregationOption(row.id, row.attributes.title, 0));
      }
    })
  }

  checkIfAddFilterIsEnabled() {
    this.addFilterEnabled = this.filtersCountFormGroup == this.filterService.getSelectedFilters()?.length;
  }

  calculateAllSelectControlsOptions(changedControlKey: string = '') {
    for (let control in this.filtersFormGroup.controls) {
      if (control != changedControlKey) {
        this.getOptions(this.filterService.getFilterDetails(control));
      }
    }
  }

  private initializeControlsByReportParams() {
    this.filterService.getSelectedReportParams().forEach((reportParam) => {
      if(reportParam.key == 'warranty_expiration_date'){
        this.addFilterControl(reportParam.key, this.filterService.getCustomControlValue(reportParam.key));
      }else{
        this.addFilterControl(reportParam.key, reportParam.value);
      }
    })
  }

  onSearch(str: string, filter: AvailableFilterDetail) {
    this.getOptions(filter, str);
  }
}
