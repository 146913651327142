import { TokenService } from "src/app/auth/token.service";

const tokenService = new TokenService();
export default class FroalaHelper{

  public static getConfig(options?: Object){
    const config: Object = {
      key: "jUA1yC3D4B1C1F1G1D1qYFa1UQRFQIVc2MSMd1IWPNb1IFd1yD2I2D1C3C7E2F3C5G1G2==",
      attribution: false,
      charCounterCount: false,
      toolbarButtons:['bold', 'italic', 'underline', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', 'insertTable', 'html']
      ,
      quickInsertTags: [],
      ...options || {},
    }

    return config;
  }
}